/**
 * Extending Renatio Formbuilder Plugin
 * 
 * Adding onclick event to submit button
 * to scroll up to success resp. error message.
 * 
 * @author bibinf\gerw
 * @returns void
 */

const addOnClickEventToSubmitButton = () => {
    const forms = document.querySelectorAll('form[data-request^=renderForm]');
    if (forms) {
        forms.forEach((form) => {
            const button = form.querySelector('button[type=submit]');
            if (button) {
                button.addEventListener('click', () => {
                    let invalid = form.querySelector('input:invalid');
                    if (invalid === null) {
                        setTimeout(() => {
                            form.previousElementSibling.scrollIntoView({behavior: "smooth"});
                        }, 1000);}
                    }
                );
            }
        });
    }
};

export { addOnClickEventToSubmitButton };
