import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faChevronUp as faChevronUpSolid } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faChevronUp as faChevronUpRegular } from '@fortawesome/pro-regular-svg-icons/faChevronUp'
import { faChevronDown as faChevronDownSolid } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight'
import { faChevronRight as faChevronRightRegular } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronLeft as faChevronLeftRegular } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faSearch as faSearchRegular } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faBinoculars as faBinocularsLight } from '@fortawesome/pro-light-svg-icons/faBinoculars'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF'
import { faMastodon } from '@fortawesome/free-brands-svg-icons/faMastodon'
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube'
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'
import { faPhone as faPhoneLight } from '@fortawesome/pro-light-svg-icons/faPhone'
import { faEnvelope as faEnvelopeLight } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faArrowToBottom as faArrowToBottomRegular } from '@fortawesome/pro-regular-svg-icons/faArrowToBottom'
import { faPencilAlt as faPencilAltRegular } from '@fortawesome/pro-regular-svg-icons/faPencilAlt'
import { faMapMarkerAlt as faMapMarkerAltLight } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt'
import { faMapMarkerAlt as faMapMarkerAltRegular } from '@fortawesome/pro-regular-svg-icons/faMapMarkerAlt'
import { faExclamationTriangle as faExclamationTriangleLight } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle'
import { faProjectDiagram as faProjectDiagramLight } from '@fortawesome/pro-light-svg-icons/faProjectDiagram'
import { faCalendarAlt as faCalendarAltRegular } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faClock as faClockRegular } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faSignInAlt } from '@fortawesome/pro-solid-svg-icons/faSignInAlt'
import { faSquareRss } from '@fortawesome/pro-solid-svg-icons/faSquareRss'
import { faUniversalAccess } from '@fortawesome/pro-regular-svg-icons/faUniversalAccess'

library.add(
  faChevronUpSolid,
  faChevronDownSolid,
  faChevronRightLight,
  faChevronRightRegular,
  faChevronLeftRegular,
  faChevronUpRegular,
  faSearchRegular,
  faBinocularsLight,
  faFacebookF,
  faMastodon,
  faYoutube,
  faInstagram,
  faWhatsapp,
  faLinkedin,
  faPhoneLight,
  faEnvelopeLight,
  faArrowToBottomRegular,
  faPencilAltRegular,
  faMapMarkerAltLight,
  faMapMarkerAltRegular,
  faExclamationTriangleLight,
  faProjectDiagramLight,
  faCalendarAltRegular,
  faClockRegular,
  faSignInAlt,
  faSquareRss,
  faUniversalAccess,
)
dom.i2svg()
