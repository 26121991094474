export const zbUtils = {
    isInViewport: (element) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    },

    scrollIntoView: (element, parent=null, axis=null, options={}) => {
        let e = element.getBoundingClientRect();
        let elementRightEdge = e.x + e.width;
        let elementBottomEdge = e.y + e.height;

        if (parent) {
            let p = parent.getBoundingClientRect();
            let parentRightEdge = p.x + p.width;
            let parentBottomEdge = p.y + p.height;
            switch (axis) {
                case 'y':
                    // untested
                    if (parentBottomEdge < elementBottomEdge) {
                        parent.scrollTop =  elementBottomEdge - parentBottomEdge;
                    } else if (p.y > e.y) {
                        parent.scrollTop = e.y - p.y;
                    }
                    break;
                case 'x':
                    if (parentRightEdge < elementRightEdge) {
                        parent.scrollTo((elementRightEdge - parentRightEdge), {behavior: 'instant'});
                    } else if (p.x > e.x) {
                        console.log('p.x > e.x = ', p.x > e.x)
                        parent.scrollTo({left: 0, behavior: options.behavior});
                    }
                    break;
                default:
                    // untested
                    if (parentRightEdge < elementRightEdge) {
                        parent.scrollLeft = elementRightEdge - parentRightEdge;
                    } else if (p.x > e.x) {
                        parent.scrollLeft = e.x - p.x;
                    }
                    if (parentBottomEdge < elementBottomEdge) {
                        parent.scrollTop = elementBottomEdge - parentBottomEdge;
                    } else if (p.y > e.y) {
                        parent.scrollTop = e.y - p.y;
                    }
            }
        } else {
            // untested
            if (axis) {
                if (axis === 'y') {
                    element.scrollTo(e.x, 0);
                } else if (axis === 'x') {
                    element.scrollTo(0, e.y);
                }
            } else {
                element.scrollIntoView(options);
            }
        }
    }
};
